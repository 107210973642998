function getWineImage (type,size){
  if (type.includes("Sparkling")){
    if (size < 350) {
      return "/images/champagneGlass.png"
    } else {
      return "/images/champagneBottle.svg"
    }
  }
  if (type.includes("Dessert")){
    if (size < 350) {
      return "/images/fortifiedGlass.png"
    } else {
      return "/images/fortifiedBottle.png"
    }
  }
  if (type.includes("Fortified")){
    if (size < 350) {
      return "/images/fortifiedGlass.png"
    } else {
      return "/images/fortifiedBottle.png"
    }
  }
  if (type.includes("Red")){
    if (size < 350) {
      return "/images/redGlass.png"
    } else {
      return "/images/redBottle.png"
    }
  }
  if (type.includes("White")){
    if (size < 350) {
      return "/images/whiteGlass.png"
    } else {
      return "/images/whiteBottle.png"
    }
  }
  if (type.includes("Rosé")){
    if (size < 350) {
      return "/images/roseGlass.png"
    } else {
      return "/images/roseBottle.png"
    }
  }
  return null
}

export default getWineImage
