import React, { Component } from "react";
import firebase from "firebase";
import { Container, Row, Col, Table, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Collapsible from "./WineItem";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      timestamp: {},
      isPro: firebase.auth().currentUser && this.props.isPro
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.isPro !== prevProps.isPro) {
      this.setState({ isPro: firebase.auth().currentUser && this.props.isPro });
    }
  }
  componentDidMount() {
    const { handle } = this.props.match.params;
    const itemsRef = firebase.database().ref(`/users/${handle}`);
    itemsRef.on("value", snapshot => {
      let data = snapshot.val();
      let items = snapshot.val().searches;
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          title: items[item].name,
          freq: items[item].freq,
          timestamp: items[item].timestamp
        });
        //console.log(items[item].search);
      }
      this.setState({
        items: newState,
        email: data.email,
        pro_active: data["pro-membership"]
          ? data["pro-membership"].active
          : false,
        pro_trial: data["pro-membership"]
          ? data["pro-membership"].trial
          : false,
        pro_trialBegan: data["pro-membership"]
          ? data["pro-membership"].trialBegan
          : null,
        restaurant: null
      });
    });

    const itemsRef2 = firebase.database().ref(`/users/${handle}`);
    itemsRef2.on("value", snapshot => {
      let data2 = snapshot.val();
      let items2 = snapshot.val().savedWines;
      let ids = [];
      let obj2 = {};
      for (let item2 in items2) {
        ids.push(items2[item2].reference);
        obj2[items2[item2].reference] = {
          timestamp: items2[item2].timestamp,
          id: item2
        };
      }
      this.setState({ timestamp: obj2 });
      fetch(
        `https://us-central1-coral-field-215519.cloudfunctions.net/function-2?_id=${ids}`,
        {
          method: "POST", // or 'PUT'
          body: JSON.stringify({}), // data can be `string` or {object}!
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
        .then(response => response.json())
        .then(response => {
          this.setState({ restaurant: response });
          //console.log(this.state.restaurant);
        });
    });
  }

  removeItem = itemId => {
    const { handle } = this.props.match.params;
    const itemRef = firebase
      .database()
      .ref(`/users/${handle}/searches/${itemId}`);
    itemRef.remove();
  };

  updateWine = (itemId, field) => {
    const { handle } = this.props.match.params;
    const itemRef = firebase
      .database()
      .ref(`/users/${handle}/savedWines/${itemId}`);
    var newObj = {};
    newObj[field] = true;
    itemRef.update(newObj);
  };

  removeWine = itemId => {
    const { handle } = this.props.match.params;
    const itemRef = firebase
      .database()
      .ref(`/users/${handle}/savedWines/${itemId}`);
    itemRef.remove();
  };

  render() {
    const { handle } = this.props.match.params;
    const { items } = this.state;
    return (
      <Container>
        <Row>
          <Col md={{ offset: 2, span: 8 }}>
            <Card className="mt-5">
              <Card.Header as="h5">User info</Card.Header>
              <Card.Body>
                <Card.Text>Email: {this.state.email}</Card.Text>
                <Card.Text>
                  Pro: {this.state.pro_active ? "active" : "not active"}
                </Card.Text>
                <Card.Text>
                  Pro Trial: {this.state.pro_trial ? "active" : "not active"}
                </Card.Text>
                <Card.Text>Trial began: {this.state.pro_trialBegan&&new Date(this.state.pro_trialBegan).toString()}</Card.Text>
              </Card.Body>
            </Card>

            {items.length > 0 && (
              <Card className="mt-5">
                <Card.Header as="h5">Saved Searches</Card.Header>
                <Card.Body>
                  <div class="table-responsive">
                    <Table>
                      <tr>
                        <th>Name</th>
                        <th>Email?</th>
                        <th>Timestamp</th>
                        <th>Delete</th>
                      </tr>

                      {items.slice(0).reverse().map(item => {
                        return (
                          <tr key={item.id}>
                            <th>
                              <Link
                                to={"/user/" + handle + "/saved/" + item.id}
                              >
                                {item.title}
                              </Link>
                            </th>
                            <th>
                              <Link
                                to={"/user/" + handle + "/saved/" + item.id}
                              >
                                {item.freq}
                              </Link>
                            </th>
                            <th>
                              <Link
                                to={"/user/" + handle + "/saved/" + item.id}
                              >
                                {item.timestamp&&new Date(item.timestamp).toString()}
                              </Link>
                            </th>

                            <th>
                              <button onClick={() => this.removeItem(item.id)}>
                                Delete
                              </button>
                            </th>
                          </tr>
                        );
                      })}
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            )}


          </Col>
        </Row>
      </Container>
    );
  }
}

export { Profile };
