import React from "react";

const Stars = ({ raiting,votes }) => {
  const percents = (raiting / 5) * 100;
  return (
    <div className="star-raiting">
      <div className="star-raiting__label">{raiting}</div>
      <div className="star-raiting__stars">
        <div className="star-raiting__stars-inactive">
          <span
            className="star-raiting__stars-active"
            style={{ width: `${percents}%` }}
          ></span>
        </div>
        <div className="star-raiting__stars-hint">{votes} restaurants</div>
      </div>
    </div>
  );
};

export default Stars
