import React, { Component } from "react";
import { Container, Row, Col, Button, Form, InputGroup, Alert } from "react-bootstrap";
import { Switch, Route, Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import Popup from "reactjs-popup";
class Saved extends Component {
  render() {

    return (
      <Container style={{"max-width":"800px","padding":"25px"}}>
      <Row>
      <Col>
      <div className="result-container">
      <div >
      <div>
      <h3>How it works:</h3>
      <ul>
      <li>Check out our online marketplace to begin building your order. Our collection is fully searchable, and is easy to browse by category, price point, or keyword. </li>
      <li>Have questions? Our expert team is here to help you. Call <a href="tel:8323522406">+1 (832) 352-2406</a>, or email <a href="mailto:thecellar@thepostoak.com">thecellar@thepostoak.com</a></li>
      <li>Don’t forget to look at our Food To-Go menu to add on a food item from one of our celebrated restaurants. Not hungry? Select our delicious $2 House Potato Chips to enjoy later. <Popup
        trigger={
          <a href="javascript:void(0);">Why?</a>
        }
              position="bottom left"
            >
              {close => (
            <span>To comply with Texas law, all alcoholic sales must be accompanied by food.</span>
              )}
            </Popup></li>
      <li>Complete your order by checking out and providing a contact phone number or email address. At this time, there is no need to submit payment information. You’ll know we’ve received your order when you get a confirmation email from us. </li>
      <li>Our team will quickly pull your items from the Cellar and promptly contact you to confirm your order. Once everything is confirmed, we will collect payment information, and arrange for a pick-up or delivery time.</li>
      <li>There is no minimum order to qualify for pickup. Purchases of 12+ bottles or of $400+ qualify for free delivery anywhere in Harris County. Most orders placed before 12PM can be received the same day they are placed!</li>
      <li>Your bottles will be packed in a clean case. Curb-side service is available for all pick-up orders, so there is no need to get out of your car. All orders that we deliver can be left on your front porch, and we’ll call you the moment we drop them off. </li>
      </ul>
      <Link to="/">Return to Main Page</Link>
      </div>
      </div>
      </div>
      </Col>
      </Row>
      </Container>

    );
  }
}

export default Saved
