import ComingSoon from  "./ComingSoon";
import React, { Component } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { TileLoader } from "./TileLoader";
import { Switch, Route, Link, BrowserRouter as Router } from "react-router-dom";
import { Stars } from "./Stars";
import getWineImage from "./winetypesimage"
import { getCode } from "country-list";
import classnames from "classnames";
import firebase from "firebase";
import {JSONLD, Product, AggregateRating, Generic} from "react-structured-data";
import {
  ReactiveList,ReactiveComponent
} from "@appbaseio/reactivesearch";
import Popup from "reactjs-popup";
import Bookmark from '@material-ui/icons/Bookmark';
import BookmarkBorder from '@material-ui/icons/BookmarkBorder';


class Collapsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.collapse,
      isPro: true,
      geoDistanceValue: this.props.geoDistanceValue,
      isSignedIn: true,
      isSaved: this.props.isSaved,
      isError: this.props.isError,
      isLiked: this.props.isLiked,
      isDisliked: this.props.isDisliked,
      isSavedID: this.props.isSavedID&&this.props.isSavedID,
      isErrorID: this.props.isErrorID&&this.props.isErrorID,
      isLikedID: this.props.isLikedID&&this.props.isLikedID,
      isDislikedID: this.props.isDislikedID&&this.props.isDislikedID,
      value:"",
      notified:true,
    };
    this.togglePanel = this.togglePanel.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.collapse !== prevProps.collapse) {
      this.setState({ open: this.props.collapse });
    }
    if (this.props.isPro !== prevProps.isPro) {
      this.setState({ isPro: firebase.auth().currentUser && this.props.isPro });
    }
    if (this.props.geoDistanceValue !== prevProps.geoDistanceValue) {
      this.setState({ geoDistanceValue: this.props.geoDistanceValue });
    }
    if (this.props.isSignedIn !== prevProps.isSignedIn) {
      this.setState({
        isSignedIn: firebase.auth().currentUser && this.props.isSignedIn
      });
    }
    if (this.props.isSaved !== prevProps.isSaved) {
      this.setState({ isSaved: this.props.isSaved });
    }
    if (this.props.isError !== prevProps.isError) {
      this.setState({ isError: this.props.isError });
    }
    if (this.props.isLiked !== prevProps.isLiked) {
      this.setState({ isLiked: this.props.isLiked });
    }
    if (this.props.isDisliked !== prevProps.isDisliked) {
      this.setState({ isDisliked: this.props.isDisliked });
    }

    if (this.props.isSavedID !== prevProps.isSavedID) {
      this.setState({ isSavedID: this.props.isSavedID });
    }
    if (this.props.isErrorID !== prevProps.isErrorID) {
      this.setState({ isErrorID: this.props.isErrorID });
    }
    if (this.props.isLikedID !== prevProps.isLikedID) {
      this.setState({ isLikedID: this.props.isLikedID });
    }
    if (this.props.isDislikedID !== prevProps.isDislikedID) {
      this.setState({ isDislikedID: this.props.isDislikedID });
    }
  }


  write = (e,type) => {
    var newRef = firebase
      .database()
      .ref("users/")
      .child(firebase.auth().currentUser.uid)
      .child(type)
      .push({
        reference: this.props.referenceID ? this.props.referenceID : null,
        data: this.props.inputData ? this.props.inputData : null,
        timestamp: firebase.database.ServerValue.TIMESTAMP
      })
      .then((snap) => {
     var hello = {}
     hello[type]=true
     hello[type+"ID"]=snap.key
     this.setState(hello)

  });
e.stopPropagation()
  };

  delete = (e,type,id) => {
    firebase
      .database()
      .ref("users/")
      .child(firebase.auth().currentUser.uid)
      .child(type)
      .child(id).remove();
      var hello = {}
      hello[type]=false
      this.setState(hello)
      e.stopPropagation()
  };

  togglePanel(e) {
    //this.setState({ open: !this.state.open });
  }

  perc2color = (perc) => {
      var r, g, b = 0;
      b=150;
      if(perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
      }
      else {
        g = 255;
        r = Math.round(510 - 5.10 * perc);
      }
      var h = r * 0x10000 + g * 0x100 + b * 0x1;
      return '#' + ('000000' + h.toString(16)).slice(-6);
    }

    getColor = (x,red,green) => {
      if (x==""){
        return 'transparent'
      } else {
      x = Math.max(Math.min(x,Math.max(red,green)),Math.min(red,green))
      return this.perc2color((x-red)/(green-red)*100)
    }
    }

    handleChange = event => {
      this.setState({ value: event.target.value });
      event.preventDefault()
    };

    handleSubmit = (event, close) => {
      firebase
        .database()
        .ref("gluglist/")
        .child("restaurant/")
        .push({
          timestamp: firebase.database.ServerValue.TIMESTAMP,
          name: this.state.value,
          restaurant: this.props.inputData.ayelpDisplayNameCity
        });
      //console.log("success");
      this.setState({searchSaved:true, notified:false})
      event.preventDefault();
      //close;
    };


  render() {

    var customFontStyle = this.props.restaurantDetails&&this.props.restaurantDetails.font?{"font-family":this.props.restaurantDetails.font}:{}
    var customButtonStyle = this.props.restaurantDetails&&this.props.restaurantDetails.accentcolor?
    {"background-color":this.props.restaurantDetails.accentcolor,
      "border-color":this.props.restaurantDetails.accentcolor,
    "color":this.props.restaurantDetails.accentfontcolor?this.props.restaurantDetails.accentfontcolor:null}:{}

    const item = this.props.inputData;
    var beforeTheSize = item.estSizeOwner?item.estSizeOwner:item.estSize
    var theSize = beforeTheSize.toFixed(0)=="1499"?1500:beforeTheSize
    var middlePrice = item.ppriceOwner?item.ppriceOwner:item.pprice
    var donate = false

    var finalPrice = item.pprice


    var wineStringA = item.wineString && item.wineString.trim()

      .replace(/\|/g, " ")
      .replace(/\/  \/  /g, "  ")
            .replace(/\/\s\/\s/g," ")
            .replace(/\//g," | ")
            .trim()


    var wineStringB = item.body && item.body.trim()

  .replace(/\|/g, " ")
  .replace(/\/  \/  /g, "  ")
        .replace(/\/\|\/\|/g, "  ")
        .replace(/\//g, " | ")
          .replace(/\/\s\/\s/g," ").trim()


    const wineString = wineStringB

    //console.log (wineStringA,wineStringB,'test',wineString)
    var beforeTimeString = item.Timestamp.substring(0,1)=="1"? "2" + item.Timestamp.substring(1,10) : item.Timestamp.substring(0,10)
    var rearrangedTimeString = beforeTimeString.substring(5,7) +"-"+ beforeTimeString.substring(8,10) +"-"+ beforeTimeString.substring(0,4)
    var timeString = item.Timestamp.substring(0,1)=="1"? "Accessed " + rearrangedTimeString: "Published "+rearrangedTimeString



    return (
      (item.inventory!=0)&&(item.inventory!=-1)&&(item.inventory!=-2)&&!item.ownerDelete?<div
        key={item._id}
        className="result-item"
        className={classnames("result-item", {
          "is-collapsed": this.state.open
        })}

      >
      <JSONLD>
        <Product name={item.aname}>
          <AggregateRating ratingValue={item.ourRating/40+2.5} reviewCount={item.ratingNRestaurants} brand={item.aproducer}/>
          <Generic type="offers" jsonldtype="Offer" schema={{price: item.currAdjPrice, priceCurrency:"USD", areaServed:item.yelpLocation.city}}/>
        </Product>
      </JSONLD>
      <Row>


      <Col xs={11} md={12} onClick={e => this.togglePanel(e)} style={{padding:"0px 5px"}}>



        {this.state.open ? (
          <>
          <Row>
          <Col xs={10} style={{padding:"0px 5px"}}>
            <h3 className="result-item__title is-collapsed">


                {this.props.placeNumber==0?wineStringB:"..."}{" "}
                {this.props.restaurantDetails&&this.props.restaurantDetails.hideSizeLabels?null:<small><small>{theSize==180||theSize==90||theSize==60||theSize==30?((theSize/30).toFixed(0)+"oz"):(theSize.toFixed(0) + "ml ")}</small></small>}
                {!!item.binStr&&<Button className="result-item__save" style={{"background-color":"white","border-color": "lightgray", "color":"black"}} size="sm">Bin {item.binStr}</Button>}
              &nbsp;






            </h3>
        </Col>
        <Col xs={2} align="right" style={{padding:"0px 5px"}}>
          <span className="result-item__count-value" >
            {false&&item.yelpPrice[0]}{finalPrice.toFixed(0)}
          </span>
        </Col>
      </Row>
</>
        ) : (
          <>
            <div className="result-item__head">





              <h3 className="result-item__title is-collapsed">


                  {wineStringB}{" "}

                &nbsp;






              </h3>

              <div className="result-item__count">
                <div className="result-item__info">

                  {theSize > 0 ? (
                    <span className="result-item__count-value">
                      {this.props.restaurantDetails&&this.props.restaurantDetails.hideSizeLabels?null:<small><small>{theSize==180||theSize==90||theSize==60||theSize==30?((theSize/30).toFixed(0)+"oz"):(theSize.toFixed(0) + "ml ")}</small></small>}
                    </span>
                  ) : null}
                  <span className="result-item__count-value">
                    {false&&item.yelpPrice[0]}{finalPrice.toFixed(0)}
                  </span>
                </div>
              </div>
            </div>
            <div className="result-item__body">
              <div className="result-item__subtitle">
                <img
                  src={
                    item.xcountry === "USA"
                      ? "https://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/us.svg"
                      : item.xcountry && getCode(item.xcountry)
                      ? `https://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/${getCode(
                          item.xcountry
                        ).toLowerCase()}.svg`
                      : "https://somm.ai/static/somm.png"
                  }
                  alt={item.xcountry}
                  className="result-item__flag"
                />
              {item.xgrape}, {item.xregion}, {item.xproducer.split("(")[0].split("/")[0]}


            </div>

              {this.state.isPro | (item.percentileProducer && ((item.percentileProducer)/2+50)<65) ? (
                <div

                  className="result-item__place"
                >
                  <div className="result-item__place-name">
                    {item.yelpDisplayName}
                  </div>
                  <div className="result-item__place-city">
                    {item.yelpLocation.city}
                  </div>
                </div>

              ) : (
                !this.props.isOneRestaurant && (
                  <div className="result-item__place-name">
                    <div className="result-item__place">
                      <Link to={this.state.isSignedIn ? "/pro" : "/loginPro"}>
                        <div className="result-item__place-name">Try Pro Free</div>
                        <div className="result-item__place-city">
                          Find out where in {item.yelpLocation.city}
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="result-item__footer">
              {item.linkbackurl && this.state.isPro ?<div className="result-item__meta">
                <a className="result-item__meta-link"  target="_blank" href={item.linkbackurl+(item.fileType==="pdf"?"#page="+(item.pageInt+1):"")}>
                  View Wine List {item.fileType?(item.fileType==="pdf"?"(pdf)":"(html)"):null}
                </a>
                <div className="result-item__meta-date">
                  {timeString}
                </div>
              </div>:<div className="result-item__meta"><div className="result-item__meta-date">
                {timeString}
              </div></div>}


              {!!item.discountOwner&&false&&<Button className="result-item__save" style={{"background-color":"white","border-color": "lightgray", "color":"red", "text-decoration": "line-through"}} size="sm">{"$"+item.pprice.toFixed(0)}</Button>}
              {!!item.discountOwner&&false&&<Button className="result-item__save" style={{"background-color":"white","border-color": "lightgray", "color":"green"}} size="sm">{item.discountOwner.toFixed(0)}% {(item.discountOwner.toFixed(6).split(".")[1]=="142857")?"Donated!":"Off!"}</Button>}
              {false&&!!item.inventory&&<Button className="result-item__save" style={{"background-color":"white","border-color": "lightgray", "color":"black"}} size="sm">{"Qty: " +(item.inventory<=12?item.inventory.toFixed(0):"12+")}</Button>}
              {!!item.calories&&<Button className="result-item__save" style={{"background-color":"white","border-color": "lightgray", "color":"black"}} size="sm">{item.calories.toFixed(0)} Cal</Button>}
              

              {/*<Button className="result-item__save" style={{"background-color":"white","border-color": "lightgray", "color":"black"}} size="sm">{item.Category_1}</Button>*/}




            </div>
          </>
        )}
      </Col>
      <Col xs={1} md={12} style={{padding:"0px 5px"}}>
        <div align="right" class="pointerCursor">

          {this.props.cartItems.map(x=>x._id).includes(item._id)?<Bookmark style={{ color: this.props.restaurantDetails&&this.props.restaurantDetails.accentcolor?this.props.restaurantDetails.accentcolor:"#000000" }} onClick={(e)=>{
              //this.setState({isBookedmarked:false});
              e.preventDefault();
              this.props.removeProductById(e,item._id)

          }
        }/>
          :
          <BookmarkBorder style={{ color: this.props.restaurantDetails&&this.props.restaurantDetails.accentcolor?this.props.restaurantDetails.accentcolor:"#000000" }} onClick={(e)=>{
              //this.setState({isBookedmarked:true});
              e.preventDefault();
              this.props.addProduct(e,item,finalPrice,theSize,wineString)

          }
          }/>
        }


        </div>
      </Col>
    </Row>
      </div>:null
    );
  }
}

export default Collapsible;
