import React, { Component } from "react";
import { Container, Row, Col, Button, Form, InputGroup, Alert } from "react-bootstrap";
import { Switch, Route, Link, BrowserRouter as Router, withRouter } from "react-router-dom";
class Saved extends Component {
  render() {

    return (
      <Container style={{"max-width":"800px","padding":"25px"}}>
      <Row>
      <Col>
      <div className="result-container">
      <div>
      <h3>What is The Wine Concierge Program?</h3>
      The Wine Concierge Program from The Cellar At The Post Oak Hotel is the best way to shop for fine-wine in Houston. We add a fine-dining level of service and hospitality to buying wine to enjoy at home. Browse our incredible collection online. Contact us by phone or email with any questions, special requests, or for our recommendations.
      <br/>
      <br/>

    Call <a href="tel:8323522406">+1 (832) 352-2406</a>, or email <a href="mailto:thecellar@thepostoak.com">thecellar@thepostoak.com</a>
  <br/>


      <br/>

      <h5>Why Use Us?</h5>
      <ul>
      <li>Because we are the leader in Houston wine, we simply have access to more fine-wine than anyone else in town. Not seeing what you're looking for? Contact us for a recommendation, or to ask if we can source a wine for you. </li>
      <li>We’re more convenient than shopping at the liquor store or in the grocery aisle. Browse our collection on your device and arrange for a quick pick-up or delivery. </li>
      <li>We have the top talent in Texas on our team. Led by Master Sommelier Keith Goldston and a team of Advanced Sommeliers, we are equipped to answer any questions and make inspired recommendations based on what you like. </li>
      </ul>
      <Link to="/">Return to Main Page</Link>
      </div>
      </div>
      </Col>
      </Row>
      </Container>
    );
  }
}

export default Saved
