import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import Circle from "./mini/circle";
import { Container, Row, Col, Button, Form, InputGroup, FormControl,Table } from "react-bootstrap";
import StripeCheckout from 'react-stripe-checkout';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ShoppingBasketIcon from '@material-ui/icons/Bookmark';
import Badge from '@material-ui/core/Badge';
import Bookmark from '@material-ui/icons/Bookmark';

class UploadImage extends React.Component {
  state = {
    cartItems:[],
    selectedOption:'option1',
    phone:"",
    notes:"",
    option1:true,
    option2:true,
    option3:true,

  };


  componentDidMount(){
    const {removeProduct, removeProductById, clearCart, addProduct} = this.props
  }



  // action to toggle the open state of the cart



  changeCheckbox = e => {
    var obj = {}
    obj[e.target.value] = !this.state[e.target.value]
    this.setState(obj)
    e.preventDefault()
  }

  onToken = (token,address) => {
    firebase.database()
    .ref("retail/")
    .push({
      address:address,
      token: token,
      state: this.state,
      timestamp: firebase.database.ServerValue.TIMESTAMP
    })
    .then(()=>{
      this.setState({
        thankyou:true,
      });
      this.props.clearCart()
    })
    .catch(err=>{
      this.setState({
        error:true
      })
    })
    }




  render() {



    var storesList = [...new Set(this.props.cartItems.map(x => ({name:x.yelpDisplayName,tax:x.tax})).map(JSON.stringify))].map(JSON.parse)

    var stores = storesList.length
    var storesWithFoodList = [...new Set(this.props.cartItems.filter(word => word._index=="del-friscos-togo-02").map(x => x.yelpDisplayName))]
    var storesWithOutFoodList = storesList.filter(x=>!storesWithFoodList.includes(x.name))
    var storesWithOutFoodListLength = storesWithOutFoodList.length

    var totalBill = this.props.cartItems.reduce((a, b) => a + b.finalPrice*b.quantity, 0) + (this.state.selectedOption&&this.state.selectedOption === 'option2'?15:0)*stores + 5*storesWithOutFoodList.length
    var taxes = this.props.cartItems.reduce((a, b) => a + b.finalPrice*b.tax/100, 0) + storesWithOutFoodList.reduce((a, b) => a + 5*b.tax/100, 0)
    var totalBillAtax = totalBill + taxes

    return (<div class="bottomLeftFloat">
    <Container style={{    "padding-bottom": "0px"}}>
      <Row class="align-middle" style={{'background-color':'lightgray','color':'black','padding':"8px", height:"50px"}} onClick={this.props.toggleCart}>
        <Col>
          <Badge badgeContent={this.props.cartItems.length}  color="secondary" >
              <Bookmark  style={{zoom:0.8}}  style={{ fontSize: 40 }}
                />
              </Badge>
        </Col>
      <Col><center><img className="navbar-logo" src="/images/somm.png" alt="" /></center></Col>
      <Col>

      </Col>
      </Row>
      {this.state.thankyou &&<Row><div style={{'padding':'10px','background-color':'mistyRose'}}><h1>Thank you for your order! We have sent you a confirmation email, and will be in contact shortly.</h1></div></Row>}


    {this.props.cartIsOpen&&<div  className={`bottomLeftFloatclosed ${this.props.cartIsOpen&&"bottomLeftFloatopen"}`}>
      <Row style={{"background-color":"mistyRose", "padding":"10px",   "max-height":"80vh",  "overflow-y": "auto"}}>

      {this.state.thankyou&&"Thank you, you'll get an email soon!"}

      <div class="buttons" onClick={this.props.toggleCart}>
        <span class="delete-btn"></span>
      </div>

      {stores>1&&<p>Alert: You have orders from {stores} restaurants</p>}







      <Table striped bordered hover>
        <thead>
          <tr>

            <td>Description</td>
                          <td></td>
            <td>Price</td>
          </tr>
        </thead>
        <tbody>





        {
          this.props.cartItems.map((x,idx)=>



                  <tr>



                    <td>
                      <div class="description">
                        <span dangerouslySetInnerHTML={{__html: x.wineStringMod}} />{" "}{x._index!="del-friscos-togo-02"&&x.theSize &&(x.theSize==180||x.theSize==90||x.theSize==60||x.theSize==30?((x.theSize/30).toFixed(0)+"oz"):(x.theSize.toFixed(0) + "ml "))}
                      </div>
                      {x._index!="del-friscos-togo-02"&&!(x.inventory>1)&&<div className="checkbox">

                      </div>}

              </td>

              {x.inventory>1?<td>
                  {false&&<AddBoxIcon onClick={()=>{

                        if (this.props.cartItems[idx].inventory&&this.props.cartItems[idx].quantity<this.props.cartItems[idx].inventory || this.props.cartItems[idx]._index=="del-friscos-togo-02"){
                    this.setState(({cartItems}) => ({
                        cartItems: [
                            ...cartItems.slice(0,idx),
                            {
                                ...cartItems[idx],
                                quantity: cartItems[idx].quantity+1,
                            },
                            ...cartItems.slice(idx+1)
                        ]
                    }));
                  }
                  }
                }/>}
                  {x.quantity}
                  <IndeterminateCheckBoxIcon onClick={(e)=>{

                        if (this.props.cartItems[idx].quantity>1 && false){

                    this.setState(({cartItems}) => ({
                        cartItems: [
                            ...cartItems.slice(0,idx),
                            {
                                ...cartItems[idx],
                                quantity: cartItems[idx].quantity-1,
                            },
                            ...cartItems.slice(idx+1)
                        ]
                    }));
                  } else if (this.props.cartItems[idx].quantity==1) {

                    this.props.removeProduct(e,idx)
                  }



                }}/>



              </td>:<td>
              <IndeterminateCheckBoxIcon onClick={(e)=>{this.props.removeProduct(e,idx)}}/>


              </td>}

                                    <td>
                ${(x.finalPrice*x.quantity).toFixed(2)}
                </td>

                  </tr>




        )
      }




                </tbody>





    </Table>



{this.state.error&&"Oops, something went wrong! Try again. Or email hello@somm.ai"}
<Button onClick={this.props.clearCart}>Clear Shortlist</Button>

</Row></div>}






</Container>
  </div>);
  }
}

export default UploadImage
