import React, { Component } from "react";
  import { Navbar, Nav, NavDropdown, DropdownButton } from "react-bootstrap";
import { Switch, Route, Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import firebase from "firebase";



class Navigation extends Component {
  state = {
    isSignedIn: firebase.auth().currentUser && this.props.isSignedIn,
    isPro: firebase.auth().currentUser && this.props.isPro,
    city:null,
    distributor:null,
};

  componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  if (this.props.isPro !== prevProps.isPro) {
    this.setState({ isPro: firebase.auth().currentUser && this.props.isPro}) ;
  }
  if (this.props.distributor !== prevProps.distributor) {
    this.setState({ distributor: this.props.distributor}) ;
  }
  if (this.props.isSignedIn !== prevProps.isSignedIn) {
    this.setState({ isSignedIn: firebase.auth().currentUser && this.props.isSignedIn }) ;
  }

  if (this.props.city !== prevProps.city) {
    this.setState({ city: this.props.city }) ;
  }
  }




  changeCity = (city) => {
    this.props.history.push('/');
    this.props.setCity(city)
  }

  render() {

    const Button2 = () => (
      <Route render={({ history}) => (
        <NavDropdown.Item onClick={() => {firebase.auth().signOut(); history.push("/")}}>
          Logout
        </NavDropdown.Item>
      )} />
    )

    return (
      <div className="bg-light above-above-component">
        <Navbar>
          <Navbar.Brand>
            <img className="navbar-logo" src="/images/somm.png" alt="" />
          </Navbar.Brand>

        </Navbar>
      </div>
    );
  }
}

export default withRouter(Navigation)
