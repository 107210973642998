import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import Circle from "./mini/circle";
import { Container, Row, Col, Button, Form, InputGroup, FormControl,Table,ButtonGroup } from "react-bootstrap";
import Popup from "reactjs-popup";
import StripeCheckout from 'react-stripe-checkout';
import Bin from './records';
import labels from "./labels";

class UploadImage extends React.Component {
  state = {
    dontUpdateInventory:true,
    cartItems:[],
    cartIsOpen:false,
    selectedOption:'option1',
    selectedOption2:'option1',
    phone:"",
    notes:"",
    email:"",
    name:"",
    address:"",
    state:"",
    city:"",
    zip:"",
    warning:"",
    lastname:"",

    option1:true,
    option2:false,
    option3:false,
  };


  componentDidMount(){

    this.setState({...this.props.data.state,orderNumber:this.props.orderNumber,cartIsOpen:false})


  }



  // action to clear the cart items
  clearCart=()=>{
    this.setState({
      cartIsOpen:false,


    })
  }

  // action to toggle the open state of the cart
  toggleCart = () => {
    this.setState({
      cartIsOpen:!this.state.cartIsOpen,
      thankyou:false,
    });
  }

  // action to add a product to the cart
  addProduct = (e,data,finalPrice,theSize, hullo) => {

    e.preventDefault();
    var idx = this.state.cartItems.findIndex(x=>x._id === data._id);
    if (idx==-1){
      this.setState(prevState => ({
        cartItems: [
          ...prevState.cartItems,
          {
            ...data,
            quantity:1,
            finalPrice: finalPrice,
            theSize: theSize,
            wineStringMod:hullo,
            wantMore:false,
          }],
          thankyou:false,
      }));
    } else {

          if (true){
      this.setState(({cartItems}) => ({
          cartItems: [
              ...cartItems.slice(0,idx),
              {
                  ...cartItems[idx],
                  quantity: cartItems[idx].quantity+1,
              },
              ...cartItems.slice(idx+1)
          ]
      }));
    }
    }



  }

  // action to remove a product from the cart
  removeProduct = (e,data) => {
    e.preventDefault()
    this.setState(prevState => ({
      cartItems: prevState.cartItems.slice(0,data).concat(prevState.cartItems.slice(data+1)),
      cartIsOpen:true,
      thankyou:false,
    }));
  }

  changeCheckbox = e => {
    var obj = {}
    obj[e.target.value] = !this.state[e.target.value]
    this.setState(obj)
    e.preventDefault()
  }

  onToken = (e) => {
    e.preventDefault()

    if (false) {
      this.setState({warning:"Your zip code must be in Harris County."})
      return
    } else if (!this.state.email.includes("@")||!this.state.email.includes(".")) {
      this.setState({warning:"Your email must be valid."})
      return
    } else {

    firebase.database()
    .ref("landrys/")
    .push({
      token: null,
      state: this.state,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      "3 - Order Confirmed": true,
      "5 - Guest Contacted":true,
      "6 - Awaiting Delivery or Pick-Up":true,
    })
    .then(()=>{
      this.setTrue('replaced')
      this.setState({
        thankyou:true,
        warning:"",
      });
      this.clearCart()
    })
    .catch(err=>{
      this.setState({
        error:true
      })
    })
  }



    }




    handleOptionChange2 = (changeEvent) => {
      this.setState({
        selectedOption2: changeEvent.target.value
      })
    }


    setTrue = (value)=>{
      firebase.database()
      .ref("landrys/"+this.props.orderNumber)
      .update({
        [value]:true
      })
    }

    setFalse = (value)=>{
      firebase.database()
      .ref("landrys/"+this.props.orderNumber)
      .update({
        [value]:false
      })
    }



  render() {


    var storesList = [...new Set(this.state.cartItems.map(x => x.yelpDisplayName))]
    var stores = storesList.length

    var storesWithFoodList = [...new Set(this.state.cartItems.filter(word => word.category&&(word.category=="Food To Go"||word.category=="Grocery")).map(x => x.yelpDisplayName))]
    var storesWithOutFoodList = storesList.filter(x=>!storesWithFoodList.includes(x))
    var totalBill = this.state.cartItems.reduce((a, b) => a + b.finalPrice*b.quantity, 0) + (this.state.selectedOption&&this.state.selectedOption === 'option2'?15:0)*stores
    var taxRate = 0.0825
    var taxes = totalBill*taxRate
    var totalBillAtax = totalBill*(1+taxRate)
    var totalQ = this.state.cartItems.reduce((a, b) => a + b.quantity, 0)

    var above400 = totalBill > 400

    return (<div style={{'margin':"5px"}}>
    <Container>
    <Row class="align-middle" style={{'background-color':'lightgray','color':'black','padding':"5px"}} onClick={this.toggleCart}>
      <Col md={9}>
    {new Date(this.props.data.timestamp).toString()}  {this.props.orderNumber} {this.state.name&&this.state.name} {this.state.lastname&&this.state.lastname}  {this.state.email&&this.state.email} {this.state.phone&&this.state.phone}

    {labels.map(x=><>{this.props.data[x]&&  <ButtonGroup className="mr-2" aria-label="First group">
    <Button variant="secondary" size="sm" >{x}</Button><Button  variant="secondary" size="sm" onClick={()=>this.setFalse(x)}>×</Button>
  </ButtonGroup>}</>)
    }




  </Col>
    <Col md={3}>
    <div align="right"><strong>{totalQ&&totalQ.toFixed(0)} items ${totalBillAtax&&totalBillAtax.toFixed(2)} </strong></div>
    </Col>
    <div style={{padding:"5px"}}>
    <Button size="sm"style={{"margin":"0px 2px"}} >Mark as:</Button>
    {labels.map(x=><>{" "}{!this.props.data[x]&&<Button style={{"margin":"0px 2px"}} variant="outline-secondary" size="sm" onClick={()=>this.setTrue(x)}>{x}</Button>}{" "}</>)
    }</div>
    </Row>






      {this.state.cartIsOpen&&
      <Row style={{"background-color":"mistyRose", "padding":"10px"}}>



        <div class="buttons" onClick={this.toggleCart}>
          <span class="delete-btn"></span>
        </div>

        {stores>1&&<p>Alert: You have orders from {stores} restaurants</p>}

        <p>How would you prefer to receive your order?</p>


        <br/>
          {above400?<div>
            <form>
              <div className="checkbox">
                <label>
                  <input type="checkbox" value="option1" checked={this.state.option1} key={this.state.option1} onClick={()=>
                    {
                      this.setState({option1:true,option2:false})
                    }} />
                  Pick Up
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" value="option2" checked={this.state.option2} key={this.state.option2} onClick={()=>
                    {
                      this.setState({option2:true,option1:false})
                    }} />
                  Delivery (available for orders above $400)
                </label>
              </div>

            </form>
          </div>:<div><form>
            <div className="checkbox">
              <label>
                <input type="checkbox" value="option1" checked={this.state.option1} key={this.state.option1} onClick={()=>
                  {
                    this.setState({option1:true,option2:false})
                  }} />
                Pick Up
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" value="option2" checked={this.state.option2} key={this.state.option2}  />
                Delivery (available for orders above $400)
              </label>
            </div>

          </form>
        </div>}






          <InputGroup className="mb-3">

        <InputGroup.Append>
          {storesWithOutFoodList&&storesWithOutFoodList.length>0?<div><Button variant="warning" className="result-container__head-btn">
                  Purchase Food Item
                </Button>{" "}Please add a food item to complete your order! <Popup
                  trigger={
                    <a href="javascript:void(0);">Why?</a>
                  }
                        position="bottom left"
                      >
                        {close => (
                      <span>To comply with Texas law, all alcoholic sales must be accompanied by food.</span>
                        )}
                      </Popup></div>:null}
        </InputGroup.Append>
      </InputGroup>

        <Table striped bordered hover>
          <thead>
            <tr>


              <td>Orig</td>
              <td >P</td>
              <td>Q</td>
              <td>Inv</td>
              <td>Tot</td>


                              {[
            "TPO CELLAR",
            "TPO FRIDGES",
            "TPO BASEMENT",
            "MASTRO'S",
            "WILLIE G'S",
            "Bloom & Bee",
            "Oak Room",
            "Cellar 6",
          ].map(y=><td><small style={{"writing-mode": "vertical-rl",
"text-orientation": "mixed"}}>{y}</small></td>)}


          </tr>

          </thead>
          <tbody>





          {
            this.state.cartItems.map((x,idx)=><>



                    <tr>



                      <td colspan="1000">
                        <div class="description">

                          <FormControl
                            required
                            value={x.wineStringMod}
                            onChange={(e)=>{
                              e.persist()
                              this.setState(({cartItems}) => ({
                                  cartItems: [
                                      ...cartItems.slice(0,idx),
                                      {
                                          ...cartItems[idx],
                                          wineStringMod: e.target.value,
                                      },
                                      ...cartItems.slice(idx+1)
                                  ]
                              }))
                            }}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />



                        </div>


                </td>
              </tr><tr>
                <td>{x.pprice}</td>
                <td style={{"max-width":"75px"}}>
                  <FormControl
                    required

                    value={this.state.cartItems[idx].finalPrice}
                    onChange={(e)=>{
                      e.persist()
                      this.setState(({cartItems}) => ({
                          cartItems: [
                              ...cartItems.slice(0,idx),
                              {
                                  ...cartItems[idx],
                                  finalPrice: parseFloat(e.target.value),
                              },
                              ...cartItems.slice(idx+1)
                          ]
                      }))
                    }}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
          </td>

                <td >
                    <Button size="sm" onClick={()=>{

                          if (true){
                      this.setState(({cartItems}) => ({
                          cartItems: [
                              ...cartItems.slice(0,idx),
                              {
                                  ...cartItems[idx],
                                  quantity: cartItems[idx].quantity+1,
                              },
                              ...cartItems.slice(idx+1)
                          ]
                      }));
                    }
                    }
                  }>
                      +
                    </Button>
                    {x.quantity}
                    <Button size="sm" onClick={(e)=>{

                          if (this.state.cartItems[idx].quantity>1){

                      this.setState(({cartItems}) => ({
                          cartItems: [
                              ...cartItems.slice(0,idx),
                              {
                                  ...cartItems[idx],
                                  quantity: cartItems[idx].quantity-1,
                              },
                              ...cartItems.slice(idx+1)
                          ]
                      }));
                    } else if (this.state.cartItems[idx].quantity==1) {

                      this.removeProduct(e,idx)
                    }



                  }}>
                      −
                    </Button>


                </td>
                <td>
{(x.inventory&&x.inventory.toFixed(0))}
</td>

                                      <td>
                  ${(x.finalPrice*x.quantity).toFixed(2)}
                  </td>
                  {[
"TPO CELLAR",
"TPO FRIDGES",
"TPO BASEMENT",
"MASTRO'S",
"WILLIE G'S",
"Bloom & Bee",
"Oak Room",
"Cellar 6",
].map(y=><td>{Bin[parseInt(parseFloat(x.wineStringMod.split(" ")[0]))]&&Bin[parseInt(parseFloat(x.wineStringMod.split(" ")[0]))][y]}</td>)}

                    </tr>




                    </>
)
        }

        <tr >
          <td colspan="1000">
          <Button onClick={(e)=>{



            this.setState(({cartItems}) => ({
                cartItems: [
                    ...cartItems,
                    {
                        ...cartItems[0],
                        category: "addition",
                        finalPrice: 0,
                        wineStringMod: "",
                        theSize: -2000,
                        quantity:1,
                        wantMore:false,
                        _index:"del-friscos-togo-02"
                    }
                ]
            }));
          }


        }>add item</Button>
        </td>
        </tr>
        {15*stores*(this.state.selectedOption === 'option2')>0&&<tr>
          <td>Shipping</td>

          <td>{1*stores}</td>
          <td>{15*stores*(this.state.selectedOption === 'option2')}</td>
        </tr>}
        {storesWithOutFoodList&&<>
        <tr>
          <td colspan={4}><b>Subtotal</b></td>

          <td><b>${totalBill&&totalBill.toFixed(2)}</b></td>
        </tr>
        <tr>
          <td colspan={4}>{(taxRate*100).toFixed(3)}%</td>

          <td>${taxes&&taxes.toFixed(2)}</td>
        </tr></>}
        <tr>
          <td colspan={4}><b>Total</b></td>

          <td><b>${totalBillAtax&&totalBillAtax.toFixed(2)}</b></td>
        </tr>
                  </tbody>





      </Table>






  {this.state.error&&"Oops, something went wrong! Try again. Or email hello@somm.ai"}
  <Form onSubmit={this.onToken}>
    <Row>
<Col md={6}>
    <FormControl
      required
      placeholder="First Name"
      value={this.state.name}
      onChange={(e)=>{
        this.setState({name:e.target.value})
      }}
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
    />

      <FormControl
        required
        placeholder="Last Name"
        value={this.state.lastname}
        onChange={(e)=>{
          this.setState({lastname:e.target.value})
        }}
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
      />
      </Col>
<Col md={6}>
  <FormControl
      placeholder="Cell Phone Number"
      value={this.state.phone}
      onChange={(e)=>{
        this.setState({phone:e.target.value})
      }}
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
    />

    <FormControl
      placeholder="Landry's Select Club #"
      value={this.state.notes}
      onChange={(e)=>{
        this.setState({notes:e.target.value})
      }}
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
    />
    <FormControl
      required
      placeholder="Email"
      value={this.state.email}
      onChange={(e)=>{
        this.setState({email:e.target.value})
      }}
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
    />
</Col>
<Col md={6}>

    <FormControl
      placeholder="Address"
      value={this.state.address}
      onChange={(e)=>{
        this.setState({address:e.target.value})
      }}
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
    />
    <FormControl
      placeholder="City"
      value={this.state.city}
      onChange={(e)=>{
        this.setState({city:e.target.value})
      }}
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
    />

    <FormControl
      placeholder="State"
      value={this.state.state}
      onChange={(e)=>{
        this.setState({state:e.target.value})
      }}
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
    />

    <FormControl
      placeholder="Zip Code"
      value={this.state.zip}
      onChange={(e)=>{

        this.setState({zip:e.target.value})
      }}
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
    />
    </Col>
<Col md={6}>
    Preferred contact method:

        <div className="radio">
          <label>
            <input type="radio" value="option1"
                          checked={this.state.selectedOption2 === 'option1'}
                          onChange={this.handleOptionChange2} />
                        Phone
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="option2"
                          checked={this.state.selectedOption2 === 'option2'}
                          onChange={this.handleOptionChange2} />
                        Email
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="option3"
                          checked={this.state.selectedOption2 === 'option3'}
                          onChange={this.handleOptionChange2} />
                        Text
          </label>
        </div>

      </Col>
    </Row>

    <div style={{"color":"red"}}>{this.state.warning}</div>
  <Button type="submit"  size="sm">Re-Submit Order</Button>
  </Form>






</Row>}








</Container>
  </div>);
  }
}

export default UploadImage
