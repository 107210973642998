import React from "react";

class ProgressRing extends React.Component {
  constructor(props) {
    super(props);


    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }



  render() {
    const { radius, stroke, progress } = this.props;

    const strokeDashoffset = this.circumference - progress / 100 * this.circumference;

    return (
      <svg
        height={radius * 2}
        width={radius * 2}

        style={{"background-image": 'url("https://img.icons8.com/clouds/2x/camera.png")',
      "background-size": "contain"}}
       >

        <circle
          stroke="red"
          fill="transparent"
          strokeWidth={ stroke }
          strokeDasharray={ this.circumference + ' ' + this.circumference }
          style={ { strokeDashoffset } }
          stroke-width={ stroke }
          r={ this.normalizedRadius }
          cx={ radius }
          cy={ radius }
         />
      </svg>
    );
  }
}

class Example extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.progress !== prevProps.progress) {
      this.setState({ progress: this.props.progress });
    }
  }


  render() {
    return (
      <ProgressRing
        radius={ 60 }
        stroke={ 4 }
        progress={ this.state.progress }
      />
    );
  }
}

export default Example
