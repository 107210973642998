import React, { Component } from "react";
import firebase from "firebase";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  Switch,
  Route,
  Link,
  BrowserRouter as Router,
  withRouter
} from "react-router-dom";

let isProUserMaster = null;

const data = [
  {
    name: "Basic",
    price: "Free",
    geography: "Unlimited Global Winelists",
    wines: "30 Best Wines per Search",
    domains: "Search by Restaurant and City",
    bandwidth: "Basic Chat",
    markups: "See wine value",
    searches: "Save wines"
  },
  {
    name: "Pro",
    price: "$tbd",
    geography: "Unlimited Global Winelists",
    emails: "Unlimited Wines per Search",
    domains: "Search by Producer and Cuveé",
    bandwidth: "Reserve wines in advance",
    markups: "See exact markup",
    searches: "Save searchs +notifications"
  }
];

class Pro extends Component {
  state = { isSignedIn: !!firebase.auth().currentUser, isPro: isProUserMaster };

  changeName = (user, username) => {
    this.setState({
      isSignedIn: !!user,
      isPro: username
    });
  };

  setSignedInState = this.props.setSignedInState;

  tryPro = () => {};

  render() {
    const Button2 = () => (
      <Route
        render={({ history }) => (
          <Button
            size="lg"
            type="button"
            onClick={() => {
              var updates = {};
              updates[
                `/users/${
                  firebase.auth().currentUser.uid
                }/pro-membership/active`
              ] = true;
              updates[
                `/users/${firebase.auth().currentUser.uid}/pro-membership/trial`
              ] = true;
              updates[
                `/users/${
                  firebase.auth().currentUser.uid
                }/pro-membership/trialBegan`
              ] = firebase.database.ServerValue.TIMESTAMP;
              firebase
                .database()
                .ref()
                .update(updates);
              this.props.setSignedInState(firebase.auth().currentUser);
              history.push("/");
            }}
          >
            Try Pro!
          </Button>
        )}
      />
    );

    const items = data.map(i => {
      let list = [];
      for (let key in i) {
        switch (key) {
          case "name":
            list.push(
              <li className="price__item price__title" key={`price-${key}`}>
                {i[key]}
              </li>
            );
            break;

          case "price":
            list.push(
              <li className="price__item price__value" key={`price-${key}`}>
                {i[key]}
              </li>
            );
            break;

          default:
            list.push(
              <li className="price__item price__field" key={`price-${key}`}>
                {i[key]}
              </li>
            );
            break;
        }
      }
      return <ul className="price">{list}</ul>;
    });
    return (
      <Container>
        <Row>
          <Col>
            <div className="pricing-table">{items}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center">
              <Button2 size="lg">Try Pro</Button2>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export { Pro };
