import React from "react";
import ContentLoader from "react-content-loader";

const TileLoader = ({ className }) => (
  <div className={className}>
    <ContentLoader
      height={160}
      width={730}
      speed={2}
      primaryColor="#dc3545"
      secondaryColor="#212121"
    >
      {/* Heading */}
      <rect x="8" y="12" rx="4" ry="4" width="100%" height="14" />
      {/* Subheading */}
      <rect x="8" y="38" rx="3" ry="3" width="60%" height="12" />
      {/* Left */}
      <rect x="8" y="77" rx="3" ry="3" width="60%" height="7" />
      <rect x="8" y="97" rx="3" ry="3" width="60%" height="7" />
      <rect x="8" y="117" rx="3" ry="3" width="60%" height="7" />
      {/* Right */}
      <rect x="70%" y="38" rx="0" ry="0" width="25%" height="9" />
      <rect x="70%" y="59" rx="0" ry="0" width="25%" height="4" />
      <rect x="70%" y="79" rx="0" ry="0" width="25%" height="4" />
      <rect x="70%" y="98" rx="0" ry="0" width="25%" height="4" />
      <rect x="70%" y="120" rx="0" ry="0" width="25%" height="4" />
    </ContentLoader>
  </div>
);

export { TileLoader };
