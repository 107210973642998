import React, {Suspense,lazy} from "react";
import { Switch, Route, Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import "./styles/App.scss";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";

// Components
import Upload2 from './components/UploadImage2';
import Orders from './components/orders';
import { Footer } from "./components/Footer";
import Navigation from "./components/Navigation";
import About from "./components/page1";
import How from "./components/page2";

import { config } from "./config";
import { hasWindow } from "./js/utils";
import { Pro } from "./components/Pro";
import { Profile } from './components/Profile';
import { Saved } from './components/Saved';


//import Main from "./components/Main";
//import City from "./components/city";
//import MainRestaurant from "./components/MainRestaurant";
//import MainRestaurantOwner from "./components/MainRestaurantOwner";
//import Upload from './components/Upload';
//import MyForm from './components/Form';
//import MyForm2 from './components/Form2';
//import Distributor from './components/Distributor';

const Main = lazy(()=>import('./components/Main'));
const City = lazy(()=>import('./components/city'));
const MainRestaurant = lazy(()=>import('./components/MainRestaurant'));
const MainRestaurant2 = lazy(()=>import('./components/MainRestaurant2'));
const MainRestaurantTroy = lazy(()=>import('./components/MainRestaurantTroy'));
const MainRestaurantJason = lazy(()=>import('./components/MainRestaurantJason'));
const MainRestaurantOwner = lazy(()=>import('./components/MainRestaurantOwner'));
const MainRestaurantShop = lazy(()=>import('./components/MainRestaurantShop'));
const MainRestaurantHarwood = lazy(()=>import('./components/MainRestaurantHarwood'));
const Upload = lazy(()=>import('./components/Upload'));
const MyForm = lazy(()=>import('./components/Form'));
const MyForm2 = lazy(()=>import('./components/Form2'));
const Distributor = lazy(()=>import('./components/Distributor'));

if (!firebase.apps.length) {
  firebase.initializeApp(config);
  firebase.analytics();

}

const Button = withRouter(({ history }) => (
  <button
    type="button"
    onClick={() => {
      history.push("/");
    }}
  >
    Click Me!
  </button>
));




class SignInScreen extends React.Component {
  constructor(props) {
    super(props);
    // Ref to the cart component to access the addProduct action
    //this.cart = React.createRef();
  }
  // The component's Local state.
  state = {
    isSignedIn: !!firebase.auth().currentUser, // Local signed-in state.
    isPro: null,
    uiConfig: null,
    firebaseui: null,
    google:false,
    city:"New York, US",
    wine:null,
    restaurant:null,
    distributor:null,
    filenames: [],
    downloadURLs: [],
    isUploading: false,
    uploadProgress: 0,
    refresh:true,
  };

  refresh=()=>{
    this.setState({
      refresh:!this.state.refresh
    })
  }

  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0
    });

  handleProgress = progress =>
    this.setState({
      uploadProgress: progress
    });

  handleUploadError = error => {
    this.setState({
      isUploading: false
      // Todo: handle error
    });
    console.error(error);
  };

  handleUploadSuccess = async filename => {
    const downloadURL = await firebase
      .storage()
      .ref("photos")
      .child(filename)
      .getDownloadURL();

    this.setState(oldState => ({
      filenames: [...oldState.filenames, filename],
      downloadURLs: [...oldState.downloadURLs, downloadURL],
      uploadProgress: 100,
      isUploading: false
    }));
  };



  setCity = (city) => {
    this.setState({city:city});
    if (this.state.isSignedIn) {
      const itemRef = firebase
        .database()
        .ref("users/")
        .child(firebase.auth().currentUser.uid)
      itemRef.update({"cityPreference":city});
    }

  }

  setWine = (city) => {
    this.setState({wine:city});
  }

  setRestaurant = (restaurant,city) => {
    this.setState({restaurant:restaurant,city:city});
  }
  setSignedInState = user => {
    this.setState({ isSignedIn: !!user });
    if (!user) {
      console.log("logging out");
      this.setState({ isPro: false });
    } else {
      firebase
        .database()
        .ref(`/users/${user.uid}/pro-membership/active`)
        .once("value")
        .then(snapshot => {
          console.log(snapshot.val());
          var username = snapshot.val();
          this.setState({ isPro: !!username });
        });
        firebase
          .database()
          .ref(`/users/${user.uid}/distributor/handle`)
          .once("value")
          .then(snapshot => {
            console.log(snapshot.val());
            var username = snapshot.val();
            this.setState({ distributor: username });
          });
    }

    if (firebase.auth().currentUser && this.state.isSignedIn &&false){
    firebase.database().ref(`/users/${firebase.auth().currentUser.uid}/cityPreference`).on("value", snapshot => {
      this.setState({'city':snapshot.val()})
  })}

  };
  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {

    const script = document.createElement("script");

    script.src = "js/embed.js";
    script.async = true;

    document.body.appendChild(script);




    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(user => this.setSignedInState(user));

    import("firebaseui").then(obj => {
      this.setState({
        firebaseui: obj
        // Configure FirebaseUI.
      });
    });


        if (firebase.auth().currentUser && this.state.isSignedIn){
          firebase.database().ref(`/users/${firebase.auth().currentUser.uid}/cityPreference`).on("value", snapshot => {
            this.setState({'city':snapshot.val()})
        })
        };
  }


  componentDidUpdate(prevProps, prevState) {


    const { firebaseui, uiConfig } = this.state;

    if (firebaseui && !uiConfig) {
      this.setState({
        uiConfig: {
          // Popup signin flow rather than redirect flow.
          signInFlow: "popup",
          // We will display Google and Facebook as auth providers.
          signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID
          ],
          callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false
          },
          credentialHelper: firebaseui.auth.CredentialHelper.NONE,
          signInSuccessUrl: "/"
        }
      });
    }
  }



  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  addProduct = (e, data, finalPrice, theSize, g) => {
    this.cart.current.addProduct(e, data, finalPrice, theSize, g);
  }

  removeProductById = (e, data) => {
    this.cart.current.removeProductById(e, data);
  }


  render() {
    const { isSignedIn, uiConfig } = this.state;
    return (
      <div className="app-container">

        {false&&<Upload2
          ref={this.cart}
        />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ `Somm.ai QR`}</title>
                <meta name="description" content="Somm.ai QR"/>
                  <meta property="og:title" content={ `Somm.ai QR`}/>
                  <meta property="og:description" content="Somm.ai QR"/>
                  <meta property="og:image" content="/images/somm.png" />
            </Helmet>
        <Router>


          {false&&<Navigation isPro={this.state.isPro} distributor={this.state.distributor} isSignedIn={this.state.isSignedIn} city={this.state.city} setCity={this.setCity}/>}
          <main className="main">
            <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path="/pro">
                <Pro tryPro={this.tryPro} setSignedInState={this.setSignedInState}/>
              </Route>
              {hasWindow && (
                <Route exact path="/login">
                  {!isSignedIn && uiConfig ? (
                    <StyledFirebaseAuth
                      uiConfig={uiConfig}
                      firebaseAuth={firebase.auth()}
                    />
                  ) : (
                    <MainRestaurant {...this.props} setWine={this.setWine}
                                          wine={this.state.wine}
                                          setRestaurant={this.setRestaurant}
                                          restaurant={this.state.restaurant} setCity={this.setCity} city={this.state.city} isPro={this.state.isPro}
                                    isSignedIn={this.state.isSignedIn}
                                    onUploadStart={this.handleUploadStart}
                                    onUploadError={this.handleUploadError}
                                    onUploadSuccess={this.handleUploadSuccess}
                                    onProgress={this.handleProgress}

                                    refresh={this.refresh}


                                    key={Math.random()}
                                    showFoodImmediately={false}


                                    />
                  )}
                </Route>

              )}

              {hasWindow && (
                    <Route
                      exact path="/"
                      component={(props) => <City {...this.props} city={this.state.city} isPro={this.state.isPro}
                                      isSignedIn={this.state.isSignedIn}
                                      onUploadStart={this.handleUploadStart}
                                      onUploadError={this.handleUploadError}
                                      onUploadSuccess={this.handleUploadSuccess}
                                      onProgress={this.handleProgress}

                                      addProduct={this.addProduct} removeProductById={this.removeProductById}

                                      nearByLocation={this.state.nearByLocation} setnearByLocation={this.setnearByLocation}
                                      resetLocation={this.resetLocation}

                                      key={Math.random()}

                                       />
                                  }

                    />
              )}

              {hasWindow && (
                    <Route
                      exact path="/food"
                      component={(props) => <MainRestaurant {...props} setWine={this.setWine}
                      showFoodImmediately={true}
                                            wine={this.state.wine}
                                            setRestaurant={this.setRestaurant}
                                            restaurant={this.state.restaurant} setCity={this.setCity} city={this.state.city} isPro={this.state.isPro}
                                      isSignedIn={this.state.isSignedIn}
                                      onUploadStart={this.handleUploadStart}
                                      onUploadError={this.handleUploadError}
                                      onUploadSuccess={this.handleUploadSuccess}
                                      onProgress={this.handleProgress}

                                      refresh={this.refresh}



                                      key={Math.random()}


                                      />
                                  }

                    />
              )}

              {hasWindow && (
                    <Route
                      exact path="/t/:restaurant"
                      component={(props) => <MainRestaurantJason {...props} setWine={this.setWine}
                      showFoodImmediately={true}
                                            wine={this.state.wine}
                                            setRestaurant={this.setRestaurant}
                                            restaurant={this.state.restaurant} setCity={this.setCity} city={this.state.city} isPro={this.state.isPro}
                                      isSignedIn={this.state.isSignedIn}
                                      onUploadStart={this.handleUploadStart}
                                      onUploadError={this.handleUploadError}
                                      onUploadSuccess={this.handleUploadSuccess}
                                      onProgress={this.handleProgress}

                                      refresh={this.refresh}



                                      key={Math.random()}


                                      />
                                  }

                    />
              )}
              {hasWindow && (
                    <Route
                      exact path="/l/:restaurant"
                      component={(props) => <MainRestaurantShop {...props} setWine={this.setWine}
                      showFoodImmediately={true}
                                            wine={this.state.wine}
                                            setRestaurant={this.setRestaurant}
                                            restaurant={this.state.restaurant} setCity={this.setCity} city={this.state.city} isPro={this.state.isPro}
                                      isSignedIn={this.state.isSignedIn}
                                      onUploadStart={this.handleUploadStart}
                                      onUploadError={this.handleUploadError}
                                      onUploadSuccess={this.handleUploadSuccess}
                                      onProgress={this.handleProgress}

                                      refresh={this.refresh}



                                      key={Math.random()}


                                      />
                                  }

                    />
              )}


              {hasWindow && (
                    <Route
                      exact path="/owner/:key/:restaurant"
                      component={(props) => <MainRestaurantOwner {...props} setWine={this.setWine}
                                            wine={this.state.wine}
                                            setRestaurant={this.setRestaurant}
                                            restaurant={this.state.restaurant} setCity={this.setCity} city={this.state.city} isPro={this.state.isPro}
                                      isSignedIn={this.state.isSignedIn}
                                      onUploadStart={this.handleUploadStart}
                                      onUploadError={this.handleUploadError}
                                      onUploadSuccess={this.handleUploadSuccess}
                                      onProgress={this.handleProgress}

                                      refresh={this.refresh}


                                      key={Math.random()}


                                      />
                                  }

                    />
              )}



              <Route
                path="/user/:handle/saved/:id"
                component={(props) => <Main {...props}                        setWine={this.setWine}
                                      wine={this.state.wine}
                                      setRestaurant={this.setRestaurant}
                                      restaurant={this.state.restaurant}
                                      setCity={this.setCity} city={this.state.city} isPro={this.state.isPro}
                                      onUploadStart={this.handleUploadStart}
                                      onUploadError={this.handleUploadError}
                                      onUploadSuccess={this.handleUploadSuccess}
                                      onProgress={this.handleProgress}
                                      isSignedIn={this.state.isSignedIn}/>}

                                      addProduct={this.addProduct} removeProductById={this.removeProductById}

                                      key={Math.random()}

              />

              {hasWindow && (
                    <Route
                      exact path="/r/:restaurant"
                      component={(props) => <MainRestaurant {...props} setWine={this.setWine}
                                            wine={this.state.wine}
                                            setRestaurant={this.setRestaurant}
                                            restaurant={this.state.restaurant} setCity={this.setCity} city={this.state.city} isPro={this.state.isPro}
                                      isSignedIn={this.state.isSignedIn}
                                      onUploadStart={this.handleUploadStart}
                                      onUploadError={this.handleUploadError}
                                      onUploadSuccess={this.handleUploadSuccess}
                                      onProgress={this.handleProgress}

                                      nearByLocation={this.state.nearByLocation} setnearByLocation={this.setnearByLocation}

                                      refresh={this.refresh}


                                      key={Math.random()}


                                      />
                                  }

                    />
              )}
              {hasWindow && (
                    <Route
                      exact path="/k/:restaurant"
                      component={(props) => <MainRestaurantTroy {...props} setWine={this.setWine}
                                            wine={this.state.wine}
                                            setRestaurant={this.setRestaurant}
                                            restaurant={this.state.restaurant} setCity={this.setCity} city={this.state.city} isPro={this.state.isPro}
                                      isSignedIn={this.state.isSignedIn}
                                      onUploadStart={this.handleUploadStart}
                                      onUploadError={this.handleUploadError}
                                      onUploadSuccess={this.handleUploadSuccess}
                                      onProgress={this.handleProgress}

                                      nearByLocation={this.state.nearByLocation} setnearByLocation={this.setnearByLocation}

                                      refresh={this.refresh}


                                      key={Math.random()}


                                      />
                                  }

                    />
              )}
              {hasWindow && (
                    <Route
                      exact path="/j/:restaurant"
                      component={(props) => <MainRestaurant2 {...props} setWine={this.setWine}
                                            wine={this.state.wine}
                                            setRestaurant={this.setRestaurant}
                                            restaurant={this.state.restaurant} setCity={this.setCity} city={this.state.city} isPro={this.state.isPro}
                                      isSignedIn={this.state.isSignedIn}
                                      onUploadStart={this.handleUploadStart}
                                      onUploadError={this.handleUploadError}
                                      onUploadSuccess={this.handleUploadSuccess}
                                      onProgress={this.handleProgress}

                                      nearByLocation={this.state.nearByLocation} setnearByLocation={this.setnearByLocation}

                                      refresh={this.refresh}


                                      key={Math.random()}


                                      />
                                  }

                    />
              )}
              {hasWindow && (
                    <Route
                      exact path="/harwood"
                      component={(props) => <MainRestaurantHarwood {...props} setWine={this.setWine}
                                            wine={this.state.wine}
                                            setRestaurant={this.setRestaurant}
                                            restaurant={this.state.restaurant} setCity={this.setCity} city={this.state.city} isPro={this.state.isPro}
                                      isSignedIn={this.state.isSignedIn}
                                      onUploadStart={this.handleUploadStart}
                                      onUploadError={this.handleUploadError}
                                      onUploadSuccess={this.handleUploadSuccess}
                                      onProgress={this.handleProgress}

                                      nearByLocation={this.state.nearByLocation} setnearByLocation={this.setnearByLocation}

                                      refresh={this.refresh}


                                      key={Math.random()}


                                      />
                                  }

                    />
              )}
              {hasWindow && (
                    <Route
                      exact path="/owner/:key/:restaurant"
                      component={(props) => <MainRestaurantOwner {...props} setWine={this.setWine}
                                            wine={this.state.wine}
                                            setRestaurant={this.setRestaurant}
                                            restaurant={this.state.restaurant} setCity={this.setCity} city={this.state.city} isPro={this.state.isPro}
                                      isSignedIn={this.state.isSignedIn}
                                      onUploadStart={this.handleUploadStart}
                                      onUploadError={this.handleUploadError}
                                      onUploadSuccess={this.handleUploadSuccess}
                                      onProgress={this.handleProgress}

                                      nearByLocation={this.state.nearByLocation} setnearByLocation={this.setnearByLocation}

                                      refresh={this.refresh}

                                      key={Math.random()}


                                      />
                                  }

                    />
              )}
            <Route path="/user/:handle/saved"  />
            <Route path="/user/:handle/profile" component={(props) => <Profile {...props} isPro={this.state.isPro} />} />
            <Route path="/upload/:handle/:path" component={(props) => <MyForm {...props} isPro={this.state.isPro} />} />
            <Route path="/upload/:handle" component={(props) => <MyForm {...props} isPro={this.state.isPro} />} />
            <Route path="/upload" component={(props) => <MyForm {...props} isPro={this.state.isPro} />} />
            <Route path="/upload2" component={(props) => <MyForm2 {...props} isPro={this.state.isPro} />} />
            <Route path="/d/:handle" component={(props) => <Distributor {...props} distributor={this.state.distributor}
              isSignedIn={this.state.isSignedIn} isPro={this.state.isPro}/>} />
            <Route path="/about"><About/></Route>
            <Route path="/how"><How/></Route>
            <Route path="/orders/:restaurant" component={(props) => <Orders  {...props}/>}/>


            </Switch>
            </Suspense>

          </main>
        </Router>
      </div>
    );
  }
}

export default SignInScreen;
