const labels = [
"1 - Order Received",
"2 - Order Picked",
"3 - Order Confirmed",
"4 - Order Incomplete - Missing Item",
"4 - Order Incomplete - Vintage Change",
"5 - Guest Contacted",
"6 - Awaiting Delivery or Pick-Up",
"7 - Completed",
'deleted',
'replaced',

]

export default labels
