import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import { Container, Row, Col, Button, ToggleButton, ButtonGroup, ToggleButtonGroup, InputGroup, FormControl} from "react-bootstrap";

import Popup from "reactjs-popup";
import StripeCheckout from 'react-stripe-checkout';
import Order from "./order";
import labels from "./labels";

class UploadImage extends React.Component {
  state = {
    reverse:false,
    requisitioned: false,
    replaced: false,
    confirmed:false,
    paid: false,
    fulfilled: false,
    deleted: false,
    city:""
  };


  componentDidMount(){
    if (this.props.match&&this.props.match.params&&this.props.match.params.restaurant &&firebase.auth().currentUser && firebase.auth().currentUser.uid) {

      var { restaurant } = this.props.match.params;

      firebase
        .database()
        .ref("users/")
        .child(firebase.auth().currentUser.uid)
        .child("owner")
        .child("restaurants")
        .once("value")
        .then(snapshot => {

          if (snapshot.val()&&Object.values(snapshot.val()).map(x=>x.value).includes(restaurant) || firebase.auth().currentUser.uid=="JnkrSPGjKNSfugZSUWqe5ApYQuA3" || firebase.auth().currentUser.uid=="sz3YnuoPbdahUDlPQk80UoLoPbz1"){
            firebase
            .database()
            .ref("landrys")
            .orderByChild("restaurant")
            .equalTo(restaurant)
            .on("value", snapshot => {
              console.log('collapse',snapshot.val())
              this.setState({data:snapshot.val()})
          })
          }

        })




    }




  }


  filter = ([key,order])=>{


    return !labels.filter(x=>this.state[x]!="na").map(x=>this.state[x] ^ !order[x]).includes(0)
  }

  render() {

    var itemsShowing = !!this.state.data&&Object.entries(this.state.data).filter(this.filter).filter((x)=>JSON.stringify(x).toLowerCase().includes(this.state.city.toLowerCase()))




    return (<Container>

      <FormControl
        placeholder="Search"
        value={this.state.city}
        onChange={(e)=>{
          this.setState({city:e.target.value})
        }}
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
      />

      <ToggleButtonGroup type="radio" name="options"  onChange={


          val=>{
            this.setState(labels.reduce((o,key)=>({...o,[key]:val}),{}))
      }


        }>

<ToggleButton value={false}>Exclude</ToggleButton>
<ToggleButton value="na">Include</ToggleButton>
<ToggleButton value={true}>Must</ToggleButton>
<InputGroup.Append>
<InputGroup.Text>All</InputGroup.Text>
</InputGroup.Append>
</ToggleButtonGroup>

      <Row>{labels.map((x,idx)=><Col><ToggleButtonGroup type="radio" value={this.state[x]} name="options"  onChange={


                      val=>{
                        this.setState({[x]:val})
                  }


                    }>

        <ToggleButton value={false}>Exclude</ToggleButton>
        <ToggleButton value="na">Include</ToggleButton>
        <ToggleButton value={true}>Must</ToggleButton>
        <InputGroup.Append>
          <InputGroup.Text>{x}</InputGroup.Text>
        </InputGroup.Append>
      </ToggleButtonGroup></Col>)}</Row>

Sales before tax: {itemsShowing&&itemsShowing.reduce((acc,cur)=>acc+(cur[1].state&&cur[1].state.cartItems?cur[1].state.cartItems.reduce((e,f)=>e+f['finalPrice']*f['quantity'],0):0),0)}
{itemsShowing&&itemsShowing.map(x=><Order key={Math.random()} orderNumber={x[0]} data={x[1]}/>)}

  </Container>);
  }
}

export default UploadImage
